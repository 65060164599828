import moment from "moment";
import moementTimezone from "moment-timezone";
import { mapGetters } from "vuex";
import { postAPICall } from "../helpers/httpHelper";

// var moment = require("moment-timezone");
export default {
  data() {
    return {
      weekDays: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      relationshipData: {}
    };
  },
  methods: {
    async fetchRelationships(entity_id) {
      if (!this.relationshipData[entity_id]) {
        const response = await postAPICall(
          "GET",
          `/entities/${entity_id}/relationships`
        );
        if (response?.data) {
          this.$set(
            this.relationshipData,
            entity_id,
            response.data
          );
        }
      }
    },
    fetchActionIcon(action) {
      switch (action.action_button_action_type) {
        case "NAVIGATION":
          return "el-icon-location-outline"
        case "ADD_OTHER_ENTITY":
          return "el-icon-plus"
        case "ESTABLISH_RELATIONSHIP":
          switch (action.action_button_relationship_type) {
            case 1:
              return "el-icon-check"
            case 2:
              return "el-icon-circle-plus-outline"
            case 3:
              return "el-icon-circle-plus"
            case 4:
              return "el-icon-view"
            case 5:
              return "el-icon-document-add"
          }
          break;
          case "DOWNLOAD_DATA":
            return "el-icon-download"
      }
    },
    onActionButtonOpen(step, row) {
      let field = this.getField(step.template_id + '#' + step.id);
      if (field?.key && field.key.includes("#")) {
        field.key = field.key.split("#")[1];
      }
      this.quickActionDummyField = null;
      this.quickActionModal = false;
      this.quickActionDummyForm = {};
      this.quickActionDummyForm = row?.entityData?.[step.template_id] || {};
      this.quickActionDummyField = {
        ...field,
        ...{
          parent_entityId: this.currentEntity._id,
          parent_entityDataId: row._id,
          template_id: step.template_id,
          execute: true,
        }
      };
      setTimeout(() => {
        this.quickActionModal = true;
      }, 500);
    },
    async onAction(action, row) {
      this.quickActionDummyField = null;
      this.quickActionModal = false;
      this.quickActionDummyField = {
        label: action.label,
        parent_entityId: this.currentEntity._id,
        parent_entityDataId: row._id,
        action_button_type: "QUICK_ACTION",
        actions: [
          { ...action, relationship_other_entity: 'self_entity' }
        ],
        type: action.type,
        execute: true,
        new_entity_data_mapping: action.new_entity_data_mapping || {}
      };
      let response = await postAPICall("GET", `/entity-data/${row._id}`);
      this.rowEntityData = { ...response.data }
      setTimeout(() => {
        this.quickActionModal = true;
      }, 500);
    },
    async onEntityDataDelete(row) {
      this.$confirm("Are you sure to delete the data?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteEntityData(row);
      });
    },
    async deleteEntityData(entityData) {
      let params = {
        id: entityData._id,
        entity_id: entityData.entity_id,
      };
      let resourceFilters = this.selectedEntityFields
        .filter((e) => {
          if (e?.inputType == "ENTITY" && e.filters) {
            return e.filters.find((f) => f.field == "RESOURCE_MANAGEMENT");
          }
        })
        .map((e) => {
          let newField = {
            key: e.key,
            label: e.label,
            filters: e.filters,
            entity_id: e.entity_id,
            template_id: e.template_id,
          };
          if (e.data_table_key) {
            newField["data_table_key"] = e.data_table_key;
            newField["data_table_field_index"] = e.data_table_field_index;
          }
          return newField;
        });
      if (resourceFilters && resourceFilters.length) {
        params["resource_filters"] = resourceFilters;
        params["entityData"] = entityData;
      }
      await this.$store.dispatch("templatesData/deleteEntityData", params);
      if (this.getTemplateDeleteStatus) {
        // this.fetchAllEmployee();
        this.fetchEntitiesDataForTable();
        this.$notify.success({
          title: "Success",
          message: "Entity Data deleted successfully",
        });
      } else {
        this.$notify.error({
          title: "Error",
          message:
            this.getTemplateDeleteError || "Error on deleting Entity data",
        });
      }
    },
    checkButtonVisible(button) {
      if (
        this.customization !== null &&
        this.customization?.buttons &&
        this.customization.buttons.length
      ) {
        if (button == "Save & New") {
          return this.customization.buttons?.[2]?.visibility;
        } else if (button == "Duplicate") {
          return this.customization.buttons?.[5]?.visibility;
        } else if (button == "Send Notification") {
          if (this.customization?.buttons[6]) {
            return this.customization?.buttons[6]?.visibility;
          }
          return true;
        }
      }
      return true;
    },
    getButtonsList(buttons) {
      return buttons.filter((button) => {
        if (button === "Edit" && this.checkPerimission("EDIT")) {
          return true;
        } else if (button === "Delete" && this.checkPerimission("DELETE")) {
          return true;
        } else if (button === "View" && this.checkPerimission("VIEW")) {
          return true;
        } else if (
          button === "Send Notification" &&
          this.checkPerimission("SEND NOTIFICATION")
        ) {
          return true;
        }
        return false;
      });
    },
    checkGlobalFilterOnSingleStat(entity_id) {
      if (this.getCompanyDetails?.global_filters) {
        let filter = this.getCompanyDetails.global_filters.find(
          (e) => e.include_in_topbar
        );
        if (
          filter?.apply_on &&
          filter.apply_on.flatMap((e) => e.split("#")[0]).indexOf(entity_id) !=
          -1
        ) {
          return filter;
        }
        return null;
      }
      return null;
    },
    addGlobalFilters(filters, entity_id, checkGlobalFilter) {
      if (checkGlobalFilter?.apply_on && this.getGlobalFiltervalue) {
        let field = checkGlobalFilter.apply_on.find(
          (e) => e.split("#")[0] == entity_id
        );
        if (field) {
          let [entity, template, key] = field.split("#");
          if (
            entity &&
            template &&
            key &&
            !filters.find((e) => e.field == template + "#" + key)
          ) {
            filters.push({
              field: template + "#" + key,
              operator: checkGlobalFilter.operator,
              value: [this.getGlobalFiltervalue],
              value_type: "",
              value_source: "",
              data_type: "OBJECT_ID",
              query_type: "AND",
              value_field: "",
              validations: {
                data_type: "",
              },
              relation_entity_id: entity,
              data_source: "ENTITY_DATA_LIST",
              current_date: this.getCurrentDate,
              entity_id: entity_id,
            });
          }
        }
      }
      return filters;
    },
    getField(field) {
      if (
        this.selectedEntityFields &&
        this.selectedEntityFields.length &&
        field
      ) {
        let foundField = this.selectedEntityFields.find((e) => (e.key == field) || (`${e.template_id}#${e.key}` == field));
        return foundField ? foundField : null;
      }
      return null;
    },
    getTableValue(row, step) {
      let checkIsGlobalvariable = this.getDataTableColums.find(
        (e) => e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.id
      );
      if (
        checkIsGlobalvariable &&
        checkIsGlobalvariable.is_global_variable &&
        checkIsGlobalvariable.global_variable_id
      ) {
        let globalVariable = (this.globalVariablesData || []).find(
          (e) => e._id == checkIsGlobalvariable.global_variable_id
        );
        if (globalVariable) {
          if (
            globalVariable.input_type == "IMAGE" &&
            globalVariable.image_url
          ) {
            return globalVariable.image_url;
          } else if (globalVariable.value) return globalVariable.value;
        }
      }
      if (step.type == "WEEKDAYS") {
        if (row && row[step.id]) {
          if (
            checkIsGlobalvariable?.allow_multiple &&
            typeof row[step.id] == "object" &&
            row[step.id].length
          ) {
            let mappedArray = row[step.id].map((e) => this.weekDayMap[e]);
            return mappedArray.join(",");
          } else {
            return this.weekDayMap[row[step.id]];
          }
        }
        return "";
      } else if (step.type == "IMAGE") {
        let id = this.generateKeyForField(step.label);
        if (row && row[id]) {
          return row[id];
        }
      } else if (step.type == "FILE" && row && row[step.id]) {
        return row[step.id].files && row[step.id].files.length
          ? row[step.id].files
          : row[step.id];
      } else if (row && row[step.id]) {
        if (typeof row[step.id] == "number" && this.isFloat(row[step.id])) {
          return parseFloat(row[step.id]).toFixed(2);
        }
        if (step.type == "MULTI_SELECT") {
          let value = row[step.id] ? row[step.id] : "";
          if (value) {
            if (typeof value == "object" && value.length) {
              return value.join(",");
            }
            return value;
          }
          return "";
        } else if (step.type == "SELECT" && row[step.id]) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (
            field &&
            field.inputType == "SELECT" &&
            field.is_list &&
            field.global_variable_id
          ) {
            let value = field.options.find((e) => e.value == row[step.id]);
            if (value && value.name) {
              result = value.name;
            }
            result = row[step.id];
          } else {
            result = row[step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (step.type == "LIST" && row[step.id]) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (field?.list_data) {
            let value = field.list_data.find(
              (e) =>
                e.value == row.entityData[step.template_id][step.id] &&
                (e.name ==
                  row.entityData[step.template_id][step.id + "_info"] ||
                  !row.entityData[step.template_id][step.id + "_info"])
            );
            if (value && value.name) {
              result = value.name;
            } else {
              result = row[step.id];
            }
          } else {
            result = row[step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (step.type == "NUMBER" && row[step.id]) {
          return typeof row[step.id] == "number" ? row[step.id] : "";
        } else if (step.type == "CHECKBOX_GROUP") {
          if (
            row &&
            row[step.id] &&
            typeof row[step.id] == "object" &&
            row[step.id].length
          ) {
            return row[step.id].length ? row[step.id].join(",") : "";
          } else {
            return row[step.id].length ? row[step.id] : "-";
          }
        } else if (step.type == "TIME_RANGE" && row[step.id].length) {
          if (row[step.id].length) {
            return (
              this.$moment(row[step.id][0]).format("HH:mm:ss") +
              " - " +
              this.$moment(row[step.id][1]).format("HH:mm:ss")
            );
          }
        } else if (step.type == "DATE_TIME_RANGE" && row[step.id].length) {
          if (row[step.id].length) {
            return (
              this.$moment(row[step.id][0]).format("MM-DD-YYYY hh:mm:ss A") +
              " To " +
              this.$moment(row[step.id][1]).format("MM-DD-YYYY hh:mm:ss A")
            );
          }
        }
        if (row[step.id + "/name"]) {
          if (
            typeof row[step.id + "/name"] == "object" &&
            row[step.id + "/name"].length
          ) {
            return row[step.id + "/name"].join(",");
          } else if (this.checkDate(row[step.id + "/name"])) {
            const format = this.getDefaultDateFormat
              ? this.getDefaultDateFormat
              : "MM-DD-YYYY";
            return moment(row[step.id + "/name"]).format(format);
          }
          return row[step.id + "/name"];
        } else {
          return row[step.id];
        }
      }
      return "";
    },
    getFieldValue(row, step) {
      let checkIsGlobalvariable = this.selectedEntityFields.find(
        (e) => e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.id
      );
      if (
        checkIsGlobalvariable &&
        checkIsGlobalvariable.is_global_variable &&
        checkIsGlobalvariable.global_variable_id
      ) {
        let globalVariable = (this.globalVariablesData || []).find(
          (e) => e._id == checkIsGlobalvariable.global_variable_id
        );
        if (globalVariable) {
          if (
            globalVariable.input_type == "IMAGE" &&
            globalVariable.image_url
          ) {
            return globalVariable.image_url;
          } else if (globalVariable.value) return globalVariable.value;
        }
      }
      if (step.type == "WEEKDAYS") {
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][step.id]
        ) {
          if (
            checkIsGlobalvariable?.allow_multiple &&
            typeof row.entityData[step.template_id][step.id] == "object" &&
            row.entityData[step.template_id][step.id].length
          ) {
            let mappedArray = row.entityData[step.template_id][step.id].map(
              (e) => this.weekDayMap[e]
            );
            return mappedArray.join(",");
          } else {
            return this.weekDayMap[row.entityData[step.template_id][step.id]];
          }
        }
        return "";
      } else if (step.type == "HTML_CONTENT") {
        let newfd = this.currentEntity.templates.find((el) => {
          if (el.template_id === step.template_id) {
            return el.templateInfo;
          }
        });
        let val = newfd.templateInfo.sections[0].fields.find((el) => {
          let keyVal = el.key.split("#")[1];
          if (keyVal === step.id && el.input_type == "HTML_CONTENT") {
            return el;
          }
        });

        return val.content;
        // return row.entityData[step.template_id][step.id]
      } else if (step.type == "IMAGE") {
        let id = this.generateKeyForField(step.label);
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][id]
        ) {
          return row.entityData[step.template_id][id];
        }
      } else if (
        step.type == "FILE" &&
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.id]
      ) {
        return row.entityData[step.template_id][step.id].files &&
          row.entityData[step.template_id][step.id].files.length
          ? row.entityData[step.template_id][step.id].files
          : row.entityData[step.template_id][step.id];
      } else if (
        step.type == "PAY_BUTTON" &&
        row &&
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.id]
      ) {
        return row.entityData[step.template_id][step.id];
      } else if (
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        ![undefined, null, ""].includes(
          row.entityData[step.template_id][step.id]
        )
      ) {
        if (
          typeof row.entityData[step.template_id][step.id] == "number" &&
          this.isFloat(row.entityData[step.template_id][step.id])
        ) {
          return parseFloat(row.entityData[step.template_id][step.id]).toFixed(
            2
          );
        }
        if (step.type == "MULTI_SELECT") {
          let value = row.entityData[step.template_id][step.id]
            ? row.entityData[step.template_id][step.id]
            : "";
          if (value) {
            if (typeof value == "object" && value.length) {
              return value.join(",");
            }
            return "";
          }
          return "";
        } else if (
          step.type == "SELECT" &&
          row.entityData[step.template_id][step.id]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (
            field &&
            field.inputType == "SELECT" &&
            field.is_list &&
            field.global_variable_id
          ) {
            let value = field.options.find(
              (e) => e.value == row.entityData[step.template_id][step.id]
            );
            if (value && value.name) {
              result = value.name;
            }
            result = row.entityData[step.template_id][step.id];
          } else {
            result = row.entityData[step.template_id][step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "LIST" &&
          row.entityData[step.template_id][step.id]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (field?.list_data) {
            let value = field.list_data.find(
              (e) =>
                e.value == row.entityData[step.template_id][step.id] &&
                (e.name ==
                  row.entityData[step.template_id][step.id + "_info"] ||
                  !row.entityData[step.template_id][step.id + "_info"])
            );
            if (value && value.name) {
              result = value.name;
            } else {
              result = row.entityData[step.template_id][step.id];
            }
          } else {
            result = row.entityData[step.template_id][step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "NUMBER" &&
          ![undefined, null, ""].includes(
            row.entityData[step.template_id][step.id]
          )
        ) {
          let template = this.currentEntity?.templates.find(
            (e) => e.template_id == step.template_id
          );
          let field = template?.templateInfo?.sections[0]?.fields.find(
            (e) => e.key?.split("#")[1] == step.id
          );
          return typeof row.entityData[step.template_id][step.id] == "number"
            ? row.entityData[step.template_id][step.id] +
            (field &&
              field.properties &&
              field.properties?.number_type == "PERCENTAGE"
              ? "%"
              : "")
            : !isNaN(row.entityData[step.template_id][step.id])
              ? row.entityData[step.template_id][step.id]
              : "";
        } else if (step.type == "CHECKBOX_GROUP") {
          if (
            row.entityData &&
            step.template_id &&
            row.entityData[step.template_id] &&
            row.entityData[step.template_id][step.id] &&
            typeof row.entityData[step.template_id][step.id] == "object" &&
            row.entityData[step.template_id][step.id].length
          ) {
            return row.entityData[step.template_id][step.id].length
              ? row.entityData[step.template_id][step.id].join(",")
              : "";
          } else {
            return row.entityData[step.template_id][step.id].length
              ? row.entityData[step.template_id][step.id]
              : "-";
          }
        } else if (
          step.type == "TIME_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "HH:mm:ss"
              ) +
              " - " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "HH:mm:ss"
              )
            );
          }
        } else if (
          step.type == "DATE_TIME_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "MM-DD-YYYY hh:mm:ss A"
              ) +
              " To " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "MM-DD-YYYY hh:mm:ss A"
              )
            );
          }
        }
        if (row.entityData[step.template_id][step.id + "/name"]) {
          if (
            typeof row.entityData[step.template_id][step.id + "/name"] ==
            "object" &&
            row.entityData[step.template_id][step.id + "/name"].length
          ) {
            return row.entityData[step.template_id][step.id + "/name"].join(
              ","
            );
          } else if (
            this.checkDate(row.entityData[step.template_id][step.id + "/name"])
          ) {
            const format = this.getDefaultDateFormat
              ? this.getDefaultDateFormat
              : "MM-DD-YYYY";
            return moment(
              row.entityData[step.template_id][step.id + "/name"]
            ).format(format);
          }
          return row.entityData[step.template_id][step.id + "/name"];
        }
        if (row.entityData[step.template_id][step.id + "_masked"]) {
          return row.entityData[step.template_id][step.id + "_masked"];
        } else if (step.input_type == "PASSWORD") {
          const passwordLength =
            row.entityData[step.template_id][step.id].length;
          return "*".repeat(passwordLength);
        } else {
          return row.entityData[step.template_id][step.id];
        }
      }
      return "";
    },
    checkDate(string) {
      let dateStamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      if (dateStamp.test(string)) {
        return true;
      }
      return false;
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
    generateKeyForField(label) {
      let parsedLabel = label.replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");
      return parsedLabel;
    },
    getCurrencyFormat(value, key) {
      let field = this.getField(key);
      let label = "";
      if (field?.inputType == "CURRENCY") {
        let type = field.validations.currency
          ? field.validations.currency
          : "USD";
        let locale = field.validations.locale
          ? field.validations.locale
          : "en-US";
        label = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: type,
        }).format(value);
      } else if (field?.inputType == "AGGREGATE_FUNCTION") {
        let isCurrencyField = (field.selected_fields || []).find((el) => {
          if (el?.inputType == "CURRENCY" || el?.result_type == "CURRENCY") {
            return true;
          }
        });
        if (isCurrencyField) {
          let type =
            isCurrencyField &&
              isCurrencyField.validations &&
              isCurrencyField.validations.currency
              ? isCurrencyField.validations.currency
              : "USD";
          let locale =
            isCurrencyField &&
              isCurrencyField.validations &&
              isCurrencyField.validations.locale
              ? isCurrencyField.validations.locale
              : "en-US";
          label = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: type,
          }).format(value);
        } else {
          label = value;
        }
      } else {
        label = value;
      }
      return label;
    },
    checkFieldType(field, type) {
      let selectedField = this.getTemplateFields.find((e) => e.key == field);
      return selectedField && selectedField?.inputType == type;
    },
    getLabel(item, i, entity) {
      let selectedEntity = entity ? entity : {};
      let name =
        item &&
          item.entity_prime_data &&
          Object.keys(item.entity_prime_data) &&
          item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (
            item.entity_prime_data &&
            item.entity_prime_data[key] &&
            item.entity_prime_data[key + "/name"]
          ) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
                primaryField.template_id &&
                item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                    ? item.entityData[primaryField.template_id][key]
                    : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
    getPrimaryName(primaryField, item) {
      let name = "";
      let key =
        primaryField && primaryField["key"] ? primaryField["key"] : null;
      if (key) {
        if (key.includes("#")) {
          key = key.split("#")[1];
        }
        name =
          item &&
            item.entityData &&
            primaryField.template_id &&
            item.entityData[primaryField.template_id] &&
            item.entityData[primaryField.template_id][key]
            ? item.entityData[primaryField.template_id][key + "/name"] ||
            item.entityData[primaryField.template_id][key]
            : "-";
        if (
          !name &&
          item &&
          item.entity_prime_data &&
          item.entity_prime_data[key]
        ) {
          name =
            item.entity_prime_data[key + "/name"] ||
            item.entity_prime_data[key];
        }
        if (
          primaryField?.inputType == "WEEKDAYS" &&
          this.weekDayMap &&
          this.weekDayMap[name]
        ) {
          name = this.weekDayMap[name];
        } else if (primaryField?.inputType == "DATE") {
          name = moementTimezone(name)
            .tz(this.timeZone.toString())
            .format("MM-DD-YYYY");
        } else if (primaryField?.inputType == "DATE_TIME") {
          name = moementTimezone(name)
            .tz(this.timeZone.toString())
            .format("MM-DD-YYYY HH:mm:ss A");
        }
      }
      if (name && typeof name == "object" && name.length) {
        return name.join(",");
      }
      return name;
    },
    async getSelectedFieldOptions(field) {
      this.pieChartFieldGroupByOptions = [];
      this.groupByFieldEntityValues = [];
      if (field) {
        let selectedField = this.getTemplateFields.find((e) => e.key == field);
        selectedField = this.setGlobalVariables(
          [selectedField],
          this.globalVariablesData
        )[0];
        if (selectedField?.inputType == "ENTITY") {
          let params = {
            entity_id: selectedField.entity_id,
          };
          await this.$store.dispatch(
            "entities/fetchEntityRecordsForTable",
            params
          );
          if (this.getEntityRecordsForTable?.data) {
            this.groupByFieldEntityValues = this.getEntityRecordsForTable.data;
            this.groupByFieldEntity =
              this.getEntityRecordsForTable.selectedEntity;
          }
        } else if (selectedField?.inputType == "YES_OR_NO") {
          this.pieChartFieldGroupByOptions = ["YES", "NO"];
        } else {
          if (selectedField?.options) {
            this.pieChartFieldGroupByOptions = selectedField.options;
          }
        }
      }
    },
    async fetchFieldOptions(field) {
      this.pieChartFieldSelectOptions = [];
      this.pieChartFieldEntityValues = [];
      if (field) {
        let selectedField = this.getTemplateFields.find((e) => e.key == field);
        selectedField = this.setGlobalVariables(
          [selectedField],
          this.globalVariablesData
        )[0];
        if (selectedField?.inputType == "ENTITY") {
          let params = {
            entity_id: selectedField.entity_id,
          };
          await this.$store.dispatch(
            "entities/fetchEntityRecordsForTable",
            params
          );
          if (this.getEntityRecordsForTable?.data) {
            this.pieChartFieldEntityValues = this.getEntityRecordsForTable.data;
            this.pieChartFieldEntity =
              this.getEntityRecordsForTable.selectedEntity;
          }
        } else if (selectedField?.inputType == "YES_OR_NO") {
          this.pieChartFieldSelectOptions = ["YES", "NO"];
        } else {
          if (selectedField?.options) {
            this.pieChartFieldSelectOptions = selectedField.options;
          }
        }
      }
    },
    setGlobalVariables(
      fields,
      globalVariablesData,
      getTemplateDataTempVariable = null
    ) {
      return fields.map((e) => {
        if (
          e &&
          e.inputType == "SELECT" &&
          e.global_variable_id &&
          e.is_global_variable
        ) {
          let globalVariable = (globalVariablesData || []).find(
            (el) => el._id == e.global_variable_id
          );
          if (globalVariable) {
            if (globalVariable.input_type == "LIST") {
              e.options = [...globalVariable.list_data];
            } else if (globalVariable.input_type == "SELECT") {
              e.options = [...globalVariable.options];
            }
          }
        } else if (e.inputType == "IMAGE" && e.global_variable_id) {
          let globalVariable = (globalVariablesData || []).find(
            (el) => el._id == e.global_variable_id
          );
          if (globalVariable && globalVariable.image_url) {
            e.value = globalVariable.image_url;
          }
        } else if (e.inputType == "CURRENCY" && e.global_variable_id) {
          let globalVariable = (globalVariablesData || []).find(
            (el) => el._id == e.global_variable_id
          );
          if (globalVariable?.locale && globalVariable?.currency) {
            e.validations.currency = globalVariable.currency;
            e.validations.locale = globalVariable.locale;
          }
        }
        if (
          getTemplateDataTempVariable &&
          getTemplateDataTempVariable[
          e.global_variable_entity_field_template_id
          ] &&
          e.input_type == "ENTITY_VARIABLE"
        ) {
          let fields = this.getTemplateFields(
            getTemplateDataTempVariable[
            e.global_variable_entity_field_template_id
            ],
            true,
            true
          );
          let field = fields.find(
            (el) => el.key == e.global_variable_entity_field
          );
          if (
            field?.inputType == "SELECT" ||
            field?.inputType == "MULTI_SELECT" ||
            field?.inputType == "LIST" ||
            field?.inputType == "CHECKBOX_GROUP" ||
            field?.inputType == "RADIO_BUTTON_GROUP" ||
            field?.inputType == "STAR_RATING"
          ) {
            e.options = field.options;
          }
        }
        return e;
      });
    },
    removeOptionalFilters(filters, includePreferred = true) {
      return filters.filter((e) => {
        if (e.preferred_filter && includePreferred) {
          return false;
        } else if (
          e.field.indexOf("self#related_to") > -1 &&
          e.value == "ALL"
        ) {
          return false;
        }
        return true;
      });
    },
    getDateOffset(now = moment(), point) {
      let value;
      if (point == "start") {
        value = now.startOf("day").toISOString();
      } else {
        value = now.endOf("day").toISOString();
      }
      return value;
    },
    getDatePickerHelper1(picker, date) {
      let field = {
        value: "",
        today: "",
      };
      switch (picker) {
        case "custom_date_range":
          field.value = [
            this.getDateOffset(moment(date[0]), "start"),
            this.getDateOffset(moment(date[1]), "end"),
          ];
          field.today = this.mapDateWithTz(
            this.getDateByDays(0, new Date(), "end")
          );
          break;
        case "custom_date":
          field.value = [
            this.getDateOffset(moment(date), "start"),
            this.getDateOffset(moment(date), "end"),
          ];
          field.today = this.mapDateWithTz(
            this.getDateByDays(0, new Date(), "end")
          );
          break;
        case "today":
          field.value = [
            this.getDateOffset(moment(), "start"),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.mapDateWithTz(
            this.getDateByDays(0, new Date(), "end")
          );
          break;
        case "yesterday":
          field.value = [
            this.getDateOffset(moment().subtract(1, "days"), "start"),
            this.getDateOffset(moment().subtract(1, "days"), "end"),
          ];
          field.today = this.mapDateWithTz(
            this.getDateByDays(1, new Date(), "end")
          );
          break;
        case "last_7_days":
          field.value = [
            this.getDateOffset(
              moment().subtract(7, "days").startOf("day"),
              "start"
            ),
            this.getDateOffset(
              moment().subtract(1, "days").endOf("day"),
              "end"
            ),
          ];
          field.today = this.getDateByDays(
            0,
            moment().format("YYYY-MM-DD")
          );
          break;
        case "last_week":
          field.value = [
            this.getDateOffset(
              moment().subtract(1, "weeks").startOf("week"),
              "start"
            ),
            this.getDateOffset(
              moment().subtract(1, "weeks").endOf("week"),
              "end"
            ),
          ];
          field.today = this.getDateByDays(
            0,
            moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD")
          );
          break;
        case "last_month":
          field.value = [
            this.getDateOffset(
              moment().subtract(1, "months").startOf("month"),
              "start"
            ),
            this.getDateOffset(
              moment().subtract(1, "months").endOf("month"),
              "end"
            ),
          ];
          field.today = this.getDateByDays(
            0,
            moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
          );
          break;
        case "last_year":
          field.value = [
            this.getDateOffset(
              moment().subtract(1, "years").startOf("year"),
              "start"
            ),
            this.getDateOffset(
              moment().subtract(1, "years").endOf("year"),
              "end"
            ),
          ];
          field.today = this.getDateByDays(
            0,
            moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD")
          );
          break;
        case "this_year":
          field.value = [
            this.getDateOffset(moment().startOf("year"), "start"),
            this.getDateOffset(moment().endOf("year"), "end"),
          ];
          field.today = this.getDateByDays(
            0,
            moment().endOf("year").format("YYYY-MM-DD")
          );
          break;
        case "this_quarter":
          field.value = [
            this.getDateOffset(moment().startOf("quarter"), "start"),
            this.getDateOffset(moment().endOf("quarter"), "end"),
          ];
          field.today = this.getDateByDays(
            0,
            moment().endOf("quarter").format("YYYY-MM-DD")
          );
          break;
        case "last_quarter":
          field.value = [
            this.getDateOffset(
              moment().subtract(1, "quarter").startOf("quarter"),
              "start"
            ),
            this.getDateOffset(
              moment().subtract(1, "quarter").endOf("quarter"),
              "end"
            ),
          ];
          field.today = this.getDateByDays(
            0,
            moment()
              .subtract(1, "quarter")
              .endOf("quarter")
              .format("YYYY-MM-DD")
          );
          break;
        case "this_week":
          field.value = [
            this.getDateOffset(moment().startOf("week"), "start"),
            this.getDateOffset(moment().endOf("week"), "end"),
          ];
          field.today = this.getDateByDays(
            0,
            moment().endOf("week").format("YYYY-MM-DD")
          );
          break;
        case "this_month":
          field.value = [
            this.getDateOffset(moment().startOf("month"), "start"),
            this.getDateOffset(moment().endOf("month"), "end"),
          ];
          field.today = this.getDateByDays(
            0,
            moment().endOf("month").format("YYYY-MM-DD")
          );
          break;
        case "next_week":
          field.value = [
            this.getDateOffset(
              moment().add(1, "weeks").startOf("week"),
              "start"
            ),
            this.getDateOffset(moment().add(1, "weeks").endOf("week"), "end"),
          ];
          field.today = this.getDateByDays(
            0,
            moment().add(1, "weeks").endOf("week").format("YYYY-MM-DD")
          );
          break;
        case "next_month":
          field.value = [
            this.getDateOffset(
              moment().add(1, "months").startOf("month"),
              "start"
            ),
            this.getDateOffset(moment().add(1, "months").endOf("month"), "end"),
          ];
          field.today = this.getDateByDays(
            0,
            moment().add(1, "months").endOf("month").format("YYYY-MM-DD")
          );
          break;
        case "next_quarter":
          field.value = [
            this.getDateOffset(
              moment().add(1, "quarter").startOf("quarter"),
              "start"
            ),
            this.getDateOffset(
              moment().add(1, "quarter").endOf("quarter"),
              "end"
            ),
          ];
          field.today = this.getDateByDays(
            0,
            moment().add(1, "quarter").endOf("quarter").format("YYYY-MM-DD")
          );
          break;
        case "next_year":
          field.value = [
            this.getDateOffset(
              moment().add(1, "years").startOf("year"),
              "start"
            ),
            this.getDateOffset(moment().add(1, "years").endOf("year"), "end"),
          ];
          field.today = this.getDateByDays(
            0,
            moment().add(1, "years").endOf("year").format("YYYY-MM-DD")
          );
          break;
        case "this_week_to_date":
          field.value = [
            this.getDateOffset(moment().startOf("week"), "start"),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "last_week_to_date":
          field.value = [
            this.getDateOffset(
              moment().subtract(1, "weeks").startOf("week"),
              "start"
            ),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "last_month_to_date":
          field.value = [
            this.getDateOffset(
              moment().subtract(1, "months").startOf("month"),
              "start"
            ),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "this_month_to_date":
          field.value = [
            this.getDateOffset(moment().startOf("month"), "start"),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "this_quarter_to_date":
          field.value = [
            this.getDateOffset(moment().startOf("quarter"), "start"),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "last_quarter_to_date":
          field.value = [
            this.getDateOffset(
              moment().subtract(1, "quarter").startOf("quarter"),
              "start"
            ),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "last_year_to_date":
          field.value = [
            this.getDateOffset(
              moment().subtract(1, "years").startOf("year"),
              "start"
            ),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "this_year_to_date":
          field.value = [
            this.getDateOffset(moment().startOf("year"), "start"),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "past_30_days":
          field.value = [
            this.getDateOffset(moment().subtract(30, "days"), "start"),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "recent":
          field.value = [
            this.getDateOffset(moment().subtract(15, "days"), "start"),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
      }
      return field;
    },
    mapDateFilters(filters, entityId = "", entityDataId = "") {
      return filters.map((v) => {
        let e = JSON.parse(JSON.stringify(v));
        if (
          e.operator == "real_time" &&
          e.data_type == "DATE" &&
          ["custom_date", "custom_date_range"].indexOf(
            e.selected_date_picker
          ) === -1
        ) {
          let data = this.getDatePickerHelper1(e.selected_date_picker);
          e.value = data.value;
          e.today = data.today;
          e.operator = "between";
        } else if (
          e.data_source == "login_user" &&
          e.value_field &&
          this.getAuthenticatedUser[e.value_field]
        ) {
          e.value = this.getAuthenticatedUser[e.value_field];
        } else if (e.field == "self#related_to/parent") {
          e.value = entityDataId;
          e.relation_entity_id = entityId;
        } else if (e.data_type == "NUMBER" && e.operator == "between") {
          let data = [e.range1, e.range2];
          e.value = data;
        } else if (e.data_type == "CURRENCY" && e.operator == "between") {
          let data = [e.range1, e.range2];
          e.value = data;
        } else if (
          e.data_type == "DATE" &&
          e.value &&
          e.data_source == "CURRENT_DATE"
        ) {
          if (e.value == "today") {
            if (e.operator == ">") {
              e.value = moment().endOf("day").toISOString();
            } else if (e.operator == ">=") {
              e.value = moment().startOf("day").toISOString();
            } else if (e.operator == "<") {
              e.value = moment().startOf("day").toISOString();
            } else if (e.operator == "<=") {
              e.value = moment().endOf("day").toISOString();
            } else if (e.operator == "=") {
              let data = this.getDatePickerHelper1("today", moment());
              e.value = data.value;
              e.today = data.today;
              e.operator = "between";
            }
          }
        } else if (
          e.data_type == "DATE" &&
          e.value &&
          ["before", "after", "weekdays"].indexOf(e.operator) == -1
        ) {
          if (e.operator == "=") {
            let data = this.getDatePickerHelper1("custom_date", e.value);
            e.value = data.value;
            e.today = data.today;
            e.operator = "between";
          } else if (
            ["notbetween", "between"].includes(e.operator) &&
            e.value
          ) {
            let data = this.getDatePickerHelper1("custom_date_range", e.value);
            e.value = data.value;
            e.today = data.today;
          } else if (
            e.operator == "real_time" &&
            e.data_type == "DATE" &&
            e.value
          ) {
            let data = this.getDatePickerHelper1(
              e.selected_date_picker,
              e.value
            );
            e.value = data.value;
            e.today = data.today;
            e.operator = "between";
          } else {
            e.value = moment(new Date(e.value)).endOf("day");
          }
        }
        return e;
      });
    },
    getTemplateFieldsByType(tempData, types, entity = null) {
      let fields = [];
      let data = JSON.parse(JSON.stringify(tempData));
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      if (types && types.length) {
        fields = fields.filter(
          (e) =>
            types.indexOf(e.inputType) !== -1 ||
            (e.inputType == "FORMULA" && types.indexOf(e.result_type) !== -1)
        );
      }
      fields = fields.map((e) => {
        e.template_id = data._id;
        e.key =
          (entity && entity._id ? entity._id + "#" : "") +
          data._id +
          "#" +
          e.key;
        e.template_name = data.name;
        if (entity?.name) {
          e.entity_name = entity.name;
        }
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    getRequiredFields(fields, entity_id) {
      let resultFilters = [];
      if (fields && fields.length) {
        fields.forEach((filter) => {
          if (filter.entity_id === entity_id) {
            resultFilters.push(filter);
          }
        });
      }
      return resultFilters;
    },
    getDateByDays(days = 0, date = new Date(), isEnd) {
      date = new Date(date);
      date.setTime(date.getTime() - 3600 * 1000 * 24 * days);
      let value =
        moment(date).format("yyyy-MM-DD") +
        (isEnd == "end" ? "T23:59:00.000Z" : "T00:00:00.000Z");
      return value;
    },
    updateCommonFilters(standardsFilers, newFilters) {
      let result = [];
      if (newFilters && newFilters.length) {
        let i1 = [];
        standardsFilers.forEach((e) => {
          let existed = newFilters.find((field) => field.field === e.field);
          if (existed && existed.value) {
            i1.push(existed);
          } else {
            i1.push(e);
          }
        });
        newFilters.forEach((f) => {
          let existed = (i1 || []).find((field) => field.field === f.field);
          if (!existed) {
            i1.push(f);
          }
        });
        result = [...i1];
      } else {
        result = [...standardsFilers];
      }
      return result;
    },
    mapDateWithTz(value) {
      if (new Date().getTimezoneOffset() < 0) {
        value = moment(value)
          .subtract(new Date().getTimezoneOffset() * -1, "minutes")
          .toISOString();
      } else {
        value = moment(value)
          .add(new Date().getTimezoneOffset(), "minutes")
          .toISOString();
      }
      return value;
    },
    getDatePickerHelper(picker) {
      let field = {
        value: "",
        today: "",
      };
      switch (picker) {
        case "today":
          field.value = this.mapDateWithTz(
            this.getDateByDays(0, new Date(), "end")
          );
          field.today = this.mapDateWithTz(
            this.getDateByDays(0, new Date(), "end")
          );
          break;
        case "yesterday":
          field.value = this.mapDateWithTz(
            this.getDateByDays(1, new Date(), "end")
          );
          field.today = this.mapDateWithTz(
            this.getDateByDays(1, new Date(), "end")
          );
          break;
        case "last_week":
          field.value = this.getDateByDays(
            0,
            moment().subtract(1, "weeks").startOf("week").format("YYYY-MM-DD")
          );
          field.today = this.getDateByDays(
            0,
            moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD")
          );
          break;
        case "last_month":
          field.value = this.getDateByDays(
            0,
            moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD")
          );
          field.today = this.getDateByDays(
            0,
            moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
          );
          break;
        case "last_year":
          field.value = this.getDateByDays(
            0,
            moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD")
          );
          field.today = this.getDateByDays(
            0,
            moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD")
          );
          break;
        case "this_year":
          field.value = this.getDateByDays(
            0,
            moment().startOf("year").format("YYYY-MM-DD")
          );
          field.today = this.getDateByDays(
            0,
            moment().endOf("year").format("YYYY-MM-DD")
          );
          break;
        case "this_quarter":
          field.value = this.getDateByDays(
            0,
            moment().startOf("quarter").format("YYYY-MM-DD")
          );
          field.today = this.getDateByDays(
            0,
            moment().endOf("quarter").format("YYYY-MM-DD")
          );
          break;
        case "last_quarter":
          field.value = this.getDateByDays(
            0,
            moment()
              .subtract(1, "quarter")
              .startOf("quarter")
              .format("YYYY-MM-DD")
          );
          field.today = this.getDateByDays(
            0,
            moment()
              .subtract(1, "quarter")
              .endOf("quarter")
              .format("YYYY-MM-DD")
          );
          break;
        case "this_week":
          field.value = this.getDateByDays(
            0,
            moment().startOf("week").format("YYYY-MM-DD")
          );
          field.today = this.getDateByDays(
            0,
            moment().endOf("week").format("YYYY-MM-DD")
          );
          break;
        case "this_month":
          field.value = this.getDateByDays(
            0,
            moment().startOf("month").format("YYYY-MM-DD")
          );
          field.today = this.getDateByDays(
            0,
            moment().endOf("month").format("YYYY-MM-DD")
          );
          break;
        case "next_week":
          field.value = this.getDateByDays(
            0,
            moment().add(1, "weeks").startOf("week").format("YYYY-MM-DD")
          );
          field.today = this.getDateByDays(
            0,
            moment().add(1, "weeks").endOf("week").format("YYYY-MM-DD")
          );
          break;
        case "next_month":
          field.value = this.getDateByDays(
            0,
            moment().add(1, "months").startOf("month").format("YYYY-MM-DD")
          );
          field.today = this.getDateByDays(
            0,
            moment().add(1, "months").endOf("month").format("YYYY-MM-DD")
          );
          break;
        case "next_quarter":
          field.value = this.getDateByDays(
            0,
            moment().add(1, "quarter").startOf("quarter").format("YYYY-MM-DD")
          );
          field.today = this.getDateByDays(
            0,
            moment().add(1, "quarter").endOf("quarter").format("YYYY-MM-DD")
          );
          break;
        case "next_year":
          field.value = this.getDateByDays(
            0,
            moment().add(1, "years").startOf("year").format("YYYY-MM-DD")
          );
          field.today = this.getDateByDays(
            0,
            moment().add(1, "years").endOf("year").format("YYYY-MM-DD")
          );
          break;
        case "this_week_to_date":
          field.value = [
            this.getDateOffset(moment().startOf("week"), "start"),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "last_week_to_date":
          field.value = [
            this.getDateOffset(
              moment().subtract(1, "weeks").startOf("week"),
              "start"
            ),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "last_month_to_date":
          field.value = [
            this.getDateOffset(
              moment().subtract(1, "months").startOf("month"),
              "start"
            ),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "this_month_to_date":
          field.value = [
            this.getDateOffset(moment().startOf("month"), "start"),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "this_quarter_to_date":
          field.value = [
            this.getDateOffset(moment().startOf("quarter"), "start"),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "last_quarter_to_date":
          field.value = [
            this.getDateOffset(
              moment().subtract(1, "quarter").startOf("quarter"),
              "start"
            ),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "last_year_to_date":
          field.value = [
            this.getDateOffset(
              moment().subtract(1, "years").startOf("year"),
              "start"
            ),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "this_year_to_date":
          field.value = [
            this.getDateOffset(moment().startOf("year"), "start"),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "past_30_days":
          field.value = [
            this.getDateOffset(moment().subtract(30, "days"), "start"),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
        case "recent":
          field.value = [
            this.getDateOffset(moment().subtract(15, "days"), "start"),
            this.getDateOffset(moment(), "end"),
          ];
          field.today = this.getDateOffset(moment(), "end");
          break;
      }
      return field;
    },
    async getNestedRelationshipDataIds(
      child_entity_id,
      nested_child_entity_id,
      parent_entity_id,
      parent_entity_data_id
    ) {
      let params = {
        parent_entity_id: parent_entity_id,
        parent_entity_data_id: parent_entity_data_id,
        child_entity_id: child_entity_id,
        nested_child_entity_id: nested_child_entity_id,
      };
      await this.$store.dispatch(
        "entityRelationships/fetchParentEntityNestedRelationshipData",
        params
      );
      if (
        this.getParentEntityNestedRelationshipData &&
        this.getParentEntityNestedRelationshipData.child_entity_data_ids &&
        this.getParentEntityNestedRelationshipData.child_entity_data_ids.length
      ) {
        return this.getParentEntityNestedRelationshipData.child_entity_data_ids;
      }
      return [];
    },
    async applyNestedRelationalFilter(filters = [], stat) {
      filters = filters.filter((e) => e.data_source !== "login_user");
      let activeWorkspace = this.getActiveContactType?.contact_type?._id;
      let currentActiveWorkspace = (
        this.getAuthenticatedUser.contact_types || []
      ).find((e) => {
        let id =
          e.contact_type && e.contact_type._id
            ? e.contact_type._id
            : e.contact_type;
        if (id && id == activeWorkspace) {
          return true;
        }
      });
      let value =
        currentActiveWorkspace && currentActiveWorkspace.account_data_id
          ? currentActiveWorkspace.account_data_id
          : "";
      if (
        // this.getAuthenticatedUser?.contact_types &&
        stat?.selectedEntityNestedRelationalData
      ) {
        let nestedIds = [];
        if (this.getAuthenticatedUser?.contact_types) {
          nestedIds = await this.getNestedRelationshipDataIds(
            stat.selectedEntityNestedRelationalData.split("#")[0],
            stat.selectedEntityNestedRelationalData.split("#")[1],
            activeWorkspace,
            value
          );
        }
        filters.unshift({
          field: "_id",
          operator: "in",
          value: nestedIds,
          value_type: "",
          value_source: "",
          query_type: "AND",
          value_field: "",
          validations: {
            data_type: "",
          },
          nested_related_id: "",
          data_source: "self_field",
          relation_entity_id: activeWorkspace || this.$route.params.entityId,
          data_type: "",
          entity_id: stat.entity_id,
        });
      }
      return filters;
    },
    applyRelationalFilter(filters = []) {
      filters = filters.filter((e) => e.data_source !== "login_user");
      let activeWorkspace = this.getActiveContactType?.contact_type?._id;
      let currentActiveWorkspace = (
        this.getAuthenticatedUser.contact_types || []
      ).find((e) => {
        let id =
          e.contact_type && e.contact_type._id
            ? e.contact_type._id
            : e.contact_type;
        if (id && id == activeWorkspace) {
          return true;
        }
      });
      let checkExistedFilter = (filters || []).findIndex(
        (e) =>
          (e.relation_entity_id == activeWorkspace ||
            this.$route.params.entityId) &&
          e.data_source == "self_field" &&
          e.data_type == "RELATION"
      );
      if (checkExistedFilter > -1) {
        filters[checkExistedFilter].value =
          currentActiveWorkspace && currentActiveWorkspace.account_data_id
            ? currentActiveWorkspace.account_data_id
            : filters[checkExistedFilter].value;
      } else {
        filters.unshift({
          field: "self#related_to/1",
          operator: "=",
          value:
            currentActiveWorkspace && currentActiveWorkspace.account_data_id
              ? currentActiveWorkspace.account_data_id
              : "",
          value_type: "",
          value_source: "",
          query_type: "AND",
          value_field: "",
          validations: {
            data_type: "",
          },
          nested_related_id: "",
          data_source: "self_field",
          relation_entity_id: activeWorkspace || this.$route.params.entityId,
          data_type: "RELATION",
        });
      }
      return filters;
    },
  },
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveContactType"]),
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "geCustomDashboardPieStats",
      "getEntityRecordsForTable",
    ]),
    ...mapGetters("entityRelationships", [
      "getParentEntityNestedRelationshipData",
    ]),
    ...mapGetters("company", ["getCompanyDetails"]),
    ...mapGetters("auth", ["getGlobalFiltervalue"]),
    ...mapGetters("templatesData", [
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDownloadUrl",
      "getDownloadError",
      "getDuplicateEntityData",
      "getDuplicateDataCheck",
    ]),
    checkGlobalFilter() {
      if (this.getCompanyDetails?.global_filters) {
        let filter = this.getCompanyDetails.global_filters.find(
          (e) => e.include_in_topbar
        );
        if (
          filter?.apply_on &&
          filter.apply_on
            .flatMap((e) => e.split("#")[0])
            .indexOf(this.entity_id) != -1
        ) {
          return filter;
        }
        return null;
      }
      return null;
    },
    customizationButtons() {
      if (this.customization?.buttons) {
        const selectedButtons = this.customization.buttons.filter(
          (button) => button.isPrimary
        );
        return selectedButtons.map((button) => button.buttonName);
      }
      return [];
    },
  },
};
